@import "@styles/theme", "@styles/mixins";

.container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $background-secondary;
  color: $text-secondary;
  font-size: 1rem;
  min-height: 200px;
  padding: 20px 32px 70px;

  &:before {
    position: absolute;
    content: " ";
    display: block;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 83px;
    height: 40px;
    background-image: url(../../../public/images/ui/iconStripesFooter.svg);
  }

  .social {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    a {
      position: relative;
      display: block;
      text-indent: -2000px;
      width: toRem(56);
      height: toRem(56);

      &:not(:last-child) {
        margin-right: 0;
      }

      &:hover,
      &:active {
        color: $text-primary;
      }

      &:before {
        font-family: $fontawesome-brands;
        font-size: 2rem;
        content: attr(data-before);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        text-indent: 0;
      }
    }
  }

  .disclaimer {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  @include desktop {
    padding: 55px 32px 87px;
    .social {
      justify-content: center;
      margin-bottom: 20px;
      a {
        margin: 0 2rem;
      }
    }
  }
}

.legalText{
  margin-top: 1rem;
    opacity: 0.8;
    font-size: 0.5rem;
  }