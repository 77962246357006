@import "@styles/theme", "@styles/mixins";

.ctaSection {
  background-color: $background-dark;
  padding: 60px 16px 50px;
  .title {
    margin-bottom: 20px;
    text-align: center;
    h2 {
      font-family: $font-family-extra-bold;
      font-size: 32px;
      text-transform: uppercase;
      margin: 0;
      color: $text-primary;
      span {
        color: $secondary;
      }
    }
  }
  .ctaButton:not(:last-child) {
    margin-bottom: 8px;
  }

  @include desktop {
    background: url(../../../public/images/ui/bgCtaSection.svg) center repeat-x;
    padding: 42px 16px 58px;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      white-space: pre-wrap;
      margin-bottom: 23px;
      h2 {
        flex-direction: row;
        font-size: 41px;
      }
    }
    .buttonHolder {
      display: flex;
      justify-content: center;
      align-items: center;
      .ctaButton {
        margin: 0;
        width: 368px !important;
        max-width: 368px;
        &:nth-child(2) {
          margin: 0 16px;
        }
        > div {
          max-width: 368px;
          height: 48px;
        }
      }
    }
  }
}
