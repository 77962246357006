@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
  background-color: $background-dark;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    h2 {
      text-align: center;
      white-space: pre-wrap;
      display: block;
      font-family: $font-family-extra-bold;
      font-size: 24px;
      text-transform: uppercase;
      margin: 0;
      color: $text-primary;
      span {
        color: $secondary;
      }
    }
  }

  @include desktop {
    padding-top: 45px !important;
    padding-bottom: 68px !important;
    .title {
      white-space: pre-wrap;
      margin-bottom: 25px;
      h2 {
        flex-direction: row;
        font-size: 32px;
      }
    }
  }
}

.expandedGallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-dark;
  z-index: 1001;

  @include desktop {
    background-color: rgba($background-dark, 0.9);
  }

  .closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
    z-index: 100;
    color: $text-primary;
  }

  .controls {
    margin: 26px 0 0 !important;
    padding: 0 #{$section-horizontal-padding} !important;
    max-width: 100vw;
  }

  .swiperMobile {
    padding: 0 !important;
  }
}

.controlsDesktop {
  button {
    position: absolute;
    z-index: 1003;

    &.arrowRight {
      right: 30px;
      top: calc(50% - 18px);
    }

    &.arrowLeft {
      left: 30px;
      top: calc(50% - 18px);
    }
  }
}

.expandIcon {
  position: absolute;
  z-index: 2;
  margin: 1rem;
  right: 0;
  top: 0;
}

.videoHolder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  box-sizing: border-box;
  align-items: center;

  .bullet {
    width: 30px !important;
    height: 30px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    &.active {
      span {
        background-color: $bullet-color-active;
      }
    }
    span {
      transition: all $transition-time-quick $transition-easing;
      display: block;
      background-color: $white;
      width: 8px !important;
      height: 8px !important;
      transform: none;
    }
  }
}

.mainImage {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 60vw;
  max-height: 400px;
  position: relative;
  @include desktop {
    height: 40vw;
    min-height: 400px;
    max-width: unset;
    max-height: 600px;
    margin-right: toRem(16);
  }

  div,
  img,
  picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.aligner {
  @include desktop {
    // padding: 0 $section-horizontal-padding-desktop;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .thumbsDesktop {
    flex-grow: 1;
    width: 40%;
    min-width: 300px;
    max-height: 600px;
    overflow-y: auto;
    position: relative;

    button {
      opacity: 0.6;
      transition: opacity $transition-time-quick $transition-easing;

      &:hover {
        filter: brightness(50%);
      }

      &.active {
        opacity: 1;
      }

      &:nth-child(odd) {
        margin-right: toRem(14);
      }

      width: calc(50% - 0.5rem);
      margin-bottom: toRem(8);

      img {
        width: 100%;
        max-width: unset !important;
      }
    }
  }
}

.thumbs {
  margin-top: 22px;
  button {
    display: contents;
  }
img {
  width: 100%;
}
.active {
  margin: 0;
  padding: 0;
  img {
    outline: 4px solid $tertiary;
    outline-offset: -4px;
  }
}

  .bar {
    width: 100%;
    height: 4px;
    background-color: $primary;
  }
}

.expandedSlide {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    height: 100vh;
    padding: calc(#{$section-horizontal-padding-desktop} / 2) #{$section-horizontal-padding-desktop};
    img {
      max-height: calc(100vh - #{$section-horizontal-padding-desktop} / 2);
    }
  }
}

.galleryContainer {
  padding: 22px 16px 12px;

  @include desktop {
    padding: 0;
    margin: 0 clamp(70px, 4vw, 90px);
  }
}
