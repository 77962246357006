@import "@styles/theme", "@styles/mixins";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 #{$section-horizontal-padding} #{toRem(52)};

  .tab {
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    flex-grow: 1;

    &:hover,
    &.active {
      .content {
        // background-color: #2e2e2e;
        // border-color: $input-box-secondary;
      }
    }

    .content {
      position: relative;
      z-index: 2;
      line-height: toRem(32);
      color: $text-primary;
      padding: 0 toRem(10);
      transition: all $transition-time-quick $transition-easing;
      display: flex;
      align-items: center;
      min-height: 50px;
      background-color: #191615;
      h3 {
        font-size: clamp(0.9rem, 1vw, 1rem);
        font-weight: normal;
      }
    }
  }

  @include desktop {
    padding: 0;
    .tab {
      &:not(:last-child) .content:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        content: " ";
        display: block;
        width: 1px;
        height: 36px;
        background-color: #707070;
      }
      &:hover .content,
      &.active .content {
        background-color: #991241;
      }
      &:has(+ .active),
      &:has(+ :hover) {
        & .content:before {
          display: none;
        }
      }
      &.active > .content:before,
      &:hover > .content:before {
        display: none;
      }
      .content {
        position: relative;
        justify-content: center;
        background-color: transparent;
        h3 {
          color: #fff;
        }
      }
    }
  }
}
