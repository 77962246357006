@import "@styles/theme", "@styles/mixins";

.container {
  background-color: #18131f;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    h2 {
      font-family: $font-family-extra-bold;
      font-size: 32px;
      text-transform: uppercase;
      margin: 0;
      color: $text-primary;
      span {
        color: $secondary;
      }
    }
  }

  @include desktop {
    padding-bottom: 56px !important;
    background: linear-gradient(to top, #edede3 0 160px, #18131f 160px 100%);
    .title {
      white-space: pre-wrap;
      margin-bottom: 40px;
      h2 {
        flex-direction: row;
      }
    }
  }
}
