@import "@styles/theme", "@styles/mixins";

.pageHeading {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.firstSlide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border: 1px solid green;

  img {
    width: calc(100% - 110px);
    max-width: 333px;
    margin: calc(50px + 10%) auto auto 60px;

    @include sm {
      margin: calc(20px + 10%) auto 0 15%;
    }
  }
}

.slides {
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;

  h3 {
    display: block !important;
    font-weight: 500;
    text-shadow: 2px 2px #{$text-secondary};

    strong {
      display: inline !important;
      font-weight: 700;
    }
  }

  .content {
    justify-content: flex-start;
    align-items: flex-start;
    margin: calc(8.5% + 50px) 0 0 10%;
    width: max-content;
    height: fit-content;
  }

  .descriptionContent {
    margin-top: #{toRem(24)};
    font-size: 18px;
    max-width: calc(100vw - 80px);
  }

  .slidesContainer {
    width: 100%;
    height: 100%;

    > span {
      position: absolute !important;
      top: 34vh;
      left:7vw;
    }

    .topBox {
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapLogo {
        img {
          width: 100%;
        }
      }
    }

    .bottomBox {
      padding: 0 5% 0;
      margin-top: 80px;
      h2 {
        font-family: $font-family-extra-bold;
        text-transform: uppercase;
        font-size: 32px;
        &::after {
          position: relative;
          bottom: -3px;
          margin-top: 1%;
          left: 0px;
          display: block;
          width: 90%;
          height: 3.5px;
          background-color: $secondary;
          content: "";
          z-index: 1;
        }
      }
      p {
        width: 100%;
        padding-top: 15px;
      }
    }
  }
}
